import React from 'react';
const NoneContent = () => {
  return (
    <div
      className="program-topic-modal"
      style={{
        color: 'black',
        padding: 50,
        margin: 50,
        borderRadius: '1.5em',
        textAlign: 'left',
        fontSize: 12
      }}
    >
      There are currently no programs available based on your selection(s). Please check back in the
      coming weeks for programs that meet these criteria or select another option.
    </div>
  );
};

export default NoneContent;
