import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
// import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import Toolbar from './Toolbar';
import { TopicContent, NoneContent, SpeakersList } from './ModalContent';
import moment from 'moment-timezone';
// import SpeakersList from './SpeakersList';
import ReactGA from 'react-ga';
import Isi from './Isi';
// import { Left, Right } from './Toolbar';
import Modal from 'react-awesome-modal';
// import $ from 'jquery';
import TruncateText from './TruncateText';
// import SelectBox from './select-box.js';

ReactGA.initialize(process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_GA);
ReactGA.pageview(window.location.pathname + window.location.search);

const tzConverter = {
  AT: 'America/Puerto_Rico',
  ET: 'America/New_York',
  CT: 'America/Chicago',
  MT: 'America/Denver',
  MST: 'America/Phoenix',
  PT: 'America/Los_Angeles',
  AKT: 'America/Anchorage',
  HST: 'Pacific/Honolulu'
};

const api_url =
  process.env.NODE_ENV === 'development'
    ? 'http://tibsovo-cal-api.beta.visual-a.com/api'
    : process.env.REACT_APP_APIURL;

// const api_url =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:8000/api'
//     : process.env.REACT_APP_APIURL;
export default class DemoApp extends React.Component {
  constructor(props) {
    super(props);

    this.filterDefaults = {
      filterSpeaker: '',
      filterTopic: '',
      tzFilter: 'America/New_York',
      zipFilter: '',
      radiusFilter: '5'
    };

    this.state = {
      weekendsVisible: true,
      // modal flags
      isModalOpen: false,
      topics: [],
      speakers: [],
      events: [],
      toggleBio: true,
      view: 'dayGridMonth',
      modalWidth: window.innerHeight > 768 ? 750 : 750,
      modalHeight: 400,
      header: {
        left: '',
        center: 'leftArrow title rightArrow',
        right: ''
      },
      selectedEvent: false,
      selectedTopic: false,
      type: '',
      dayOrDateComparison: 'day',
      ...this.filterDefaults
    };
    this.getEvents = this.getEvents.bind(this);
    this.calendarRef = React.createRef();

    this.calendarApi = null;

    this.handleEventClick = this.handleEventClick.bind(this);
    // this.handleTzChange = this.handleTzChange.bind(this);
    // this.handleTopicChange = this.handleTopicChange.bind(this);
    // this.handleSpeakerChange = this.handleSpeakerChange.bind(this);
    this.handleFilterValueChange = this.handleFilterValueChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.renderModalBody = this.renderModalBody.bind(this);
    this.showModal = this.showModal.bind(this);
    this.changeView = this.changeView.bind(this);
    this.loadData = this.loadData.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  async loadData(allData = true) {
    const that = this;
    // this.calendarApi = this.calendarRef.current.getApi();

    let showEventualNoDataModal = false;

    const appData = {};

    if (allData) {
      const speakerJson = await fetch(`${api_url}/presenters`);
      appData.speakers = await speakerJson.json();

      const topicsJson = await fetch(`${api_url}/topics`);
      appData.topics = await topicsJson.json();
    }

    let endpoint = `${api_url}/events`;
    // console.log('loadData', 'zip', this.state.zipFilter, 'radius', this.state.radiusFilter);
    if (this.state.zipFilter && this.state.radiusFilter) {
      endpoint += `?zip_code=${this.state.zipFilter}&radius=${this.state.radiusFilter}`;
      showEventualNoDataModal = true;
    }
    const eventsJson = await fetch(endpoint);
    const eventsList = await eventsJson.json();

    // const speakers = {};
    // const topics = new Set();

    appData.events = eventsList.map((eventObj) => {
      //add fake event type just to mock up the colors
      // eventObj['type'] = idx % 2 === 0 ? 'virtual' : 'live';

      // speakers[presenter.id] = eventObj.presenter;
      // topics.add(eventObj.name);

      let momentDate = moment.tz(eventObj.start_date, tzConverter[eventObj.timezone]);

      // Need to account for origin timezone of meeting, smh
      momentDate = momentDate.tz(that.state.tzFilter);

      const { presenter, venue, meetingtype } = eventObj;

      return {
        title: `${presenter.first_name[0]} ${presenter.last_name}${
          presenter.title && `, ${presenter.title}`
        }`,
        id: eventObj.id.toString(),
        presenterId: presenter.id,
        regLink: eventObj.link,
        start: momentDate.format(),
        speakerName: `${presenter.first_name} ${presenter.last_name}`,
        speakerSpecialties: presenter.specialties,
        speakerCredentials: presenter.credentials,
        speakerTitle: presenter.title,
        speakerAffiliation: presenter.affiliation,
        speakerBio: presenter.bio,
        venueName: venue.name,
        venueAddress1: venue.address1,
        city: presenter.city,
        state: presenter.state,
        topic: eventObj.topic,
        programOverview: eventObj.objective,
        // title: presenter.topic,
        location: venue.location,
        venueCity: venue.city,
        venueState: venue.state,

        momentDate,
        type: meetingtype,
        classNames: [meetingtype === 'Fully Virtual' ? 'purpleBg' : 'redBg']
        // }
      };
    });
    // console.log(events);
    this.setState(appData);
    if (showEventualNoDataModal && !appData.events.length) {
      this.showModal('noprograms');
    }
  }

  applyFilters(events) {
    const that = this;
    let filteredEvents = events;

    if (this.state.filterSpeaker) {
      filteredEvents = filteredEvents.filter(
        (calEvent) => calEvent.presenterId.toString() === that.state.filterSpeaker
      );
    }
    if (this.state.filterTopic) {
      filteredEvents = filteredEvents.filter(
        (calEvent) => calEvent.topic === that.state.filterTopic
      );
    }
    if (this.state.tzFilter) {
      filteredEvents.forEach(
        (eventObj, index) =>
          (filteredEvents[index]['start'] = eventObj['momentDate']
            .tz(that.state.tzFilter)
            .format('YYYY-MM-DDTHH:mm:ss'))
      );
    }

    this.calendarApi.removeAllEventSources();
    this.calendarApi.addEventSource(filteredEvents);

    if (!filteredEvents.length) {
      this.showModal('noprograms');
    }
  }

  async componentDidMount() {
    this.calendarApi = this.calendarRef.current.getApi();

    this.loadData();
  }

  renderModalBody = () => {
    var s = '';
    switch (this.state.type) {
      case 'topic':
        s = <TopicContent />;
        break;
      case 'events':
        s = this.renderModal(this.selectedEvent);
        break;
      case 'speakers':
        s = <SpeakersList speakers={this.state.speakers} />;
        break;
      case 'noprograms':
        s = <NoneContent />;
        break;
      default:
        s = 'No Type Selected';
        break;
    }
    return s;
  };

  handleFilterValueChange = (filterName) => {
    const that = this;
    return (event) => {
      // console.log('handleFilterValchange', filterName, 'value', event.target.value);
      this.setState({ [filterName]: event.target.value }, () => {
        // run the applyfilters query only if its not the zip/radius filter.
        // that filter runs a full query to the api and should not trigger applyFilters
        !['radiusFilter', 'zipFilter'].includes(filterName) && this.applyFilters(that.state.events);
      });
    };
  };

  resetFilters = () => {
    // console.log('this.resetFilters', { ...this.filterDefaults });
    this.setState({ ...this.filterDefaults }, () => this.loadData(false));
  };

  renderModal = () => {
    const { selectedEvent, dayOrDateComparison } = this.state;
    let eventId;
    let selectedEventTime;

    if (!selectedEvent) {
      return '';
    }

    if (selectedEvent.event) {
      eventId = selectedEvent.event.id;
      selectedEventTime = selectedEvent.event.startStr;
    } else {
      eventId = selectedEvent.id;
      selectedEventTime = selectedEvent.start_date;
    }

    const eventsToDisplay = this.state.events.filter((event) => {
      return dayOrDateComparison === 'day'
        ? moment(event.start, 'YYYY-MM-DD').isSame(moment(selectedEventTime, 'YYYY-MM-DD'))
        : eventId === event.id;
    });

    const eventBlockStyle =
      eventsToDisplay.length > 1 ? { borderBottom: '1px solid black', marginBottom: 20 } : null;

    let content = eventsToDisplay.map((eventObj, idx) => {
      const eventSpeaker = eventObj.speakerTitle
        ? eventObj.speakerName + ',<br />' + eventObj.speakerTitle
        : eventObj.speakerName;

      return (
        <div style={eventBlockStyle}>
          <div className="flex death" style={{ color: 'black', fontWeight: 'bold' }}>
            <div>Time</div>
            <div>Speaker Name</div>
            <div>Program Topic</div>
            <div>Program Location</div>
            <div>Program Address</div>
          </div>
          <div
            key={idx}
            className={`modal__event ${
              eventObj.id === parseInt(eventId) ? 'modal_event--active' : ''
            }`}
          >
            <div className="flex death">
              <div className="modal__time">{eventObj.momentDate.format('h:mm A z')}</div>
              <div
                className="modal__doctor"
                style={{ fontWeight: 'bold', textAlign: 'center', marginLeft: 0 }}
              >
                <div key={Date()} dangerouslySetInnerHTML={{ __html: eventSpeaker }} />
              </div>
              <div>{eventObj.topic}</div>
              <div>{eventObj.venueName}</div>
              <div>
                {eventObj.venueAddress1}
                <br />
                {eventObj.venueCity}, {eventObj.venueState}
              </div>
            </div>
            <div
              onClick={() => {
                this.setState({ toggleBio: !this.state.toggleBio });
              }}
              className="bio"
              style={{ height: 'auto' }}
            >
              <span style={{ fontWeight: 'bold' }}>Speaker Bio:</span>
              <br />
              <TruncateText isOpen={false}>{eventObj.speakerBio}</TruncateText>
              <br />
              <span style={{ fontWeight: 'bold' }}>Program Overview:</span>
              <TruncateText isOpen={false}>{eventObj.programOverview}</TruncateText>
            </div>
            <a
              style={{ display: 'block' }}
              href={eventObj['regLink']}
              target="_blank"
              rel="noopener noreferrer"
              className="modal__button"
            >
              Register
            </a>
          </div>
        </div>
      );
    });

    let titleStr;
    if (selectedEvent.event) {
      titleStr = selectedEvent.event.startStr;
    } else {
      titleStr = selectedEvent.start_date;
    }
    const title = <h1>{moment(titleStr).format('MMM Do, YYYY')}</h1>;
    if (!content.length) {
      content = <div>Sorry, there are no events on this date.</div>;
    }
    // const that = this;
    return (
      <div className="modal__content" style={{ paddingTop: 20 }}>
        {title}
        <div
          style={{
            background: 'white',
            borderRadius: '30px',
            maxHeight: 600,
            overflow: 'auto',
            padding: 20
          }}
        >
          {content}
        </div>
      </div>
    );
  };

  getEvents = () => {
    return this.state.events;
  };

  changeView = (type) => {
    this.calendarApi.changeView(type);
  };

  showModal = (type) => {
    this.setState({
      isModalOpen: true,
      type: type
    });
  };

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible
    });
  };

  handleEventClick = (selectedEvent) => {
    this.setState({
      selectedEvent,
      isModalOpen: true,
      dayOrDateComparison: ''
    });
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    });
  };

  render() {
    const that = this;

    // if (!this.state.events.length) {
    //   return <div>Loading...</div>;
    // }
    return (
      <section>
        <img style={{ width: '100%' }} src={require('../assets/calendar-header.png')} alt="" />
        <div>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 12, marginTop: 10 }}>
              <div className="title--purple">Indication</div>TIBSOVO is indicated for the treatment
              of acute myeloid leukemia (AML) with a susceptible isocitrate dehydrogenase-1 (IDH1)
              mutation as detected by an <span style={{ whiteSpace: 'nowrap' }}>FDA-approved</span>{' '}
              test in:
              <ul>
                <li style={{ margin: 0, paddingTop: 2 }}>
                  Adult patients with newly-diagnosed AML who are ≥75 years old or who have
                  comorbidities that preclude use of intensive induction chemotherapy.
                </li>
                <li style={{ margin: 0, paddingTop: 2 }}>
                  Adult patients with relapsed or refractory AML.
                </li>
              </ul>
              <br />
              Thank you for your interest in attending an upcoming{' '}
              <span style={{ fontWeight: 600 }}>TIBSOVO speaker program,</span> sponsored by Servier
              Pharmaceuticals LLC.{' '}
              <span style={{ fontWeight: 600 }}>
                Servier offers a variety of TIBSOVO program topics and formats to choose from, each
                led by a national AML expert speaker.
              </span>{' '}
              Please use the filters below to select your preferences and/or click on an available
              program option to view additional details or to register.
              <br />
            </div>
            <br />
          </div>
          <div className="flex flex__column">
            <Toolbar
              events={this.state.events}
              getEvents={this.getEvents}
              values={{
                filterSpeaker: this.state.filterSpeaker,
                filterTopic: this.state.filterTopic,
                tz: this.state.tz,
                zipFilter: this.state.zipFilter,
                radiusFilter: this.state.radiusFilter
              }}
              handleFilterValueChange={this.handleFilterValueChange}
              resetFilters={this.resetFilters}
              // handleTopicChange={this.handleTopicChange}
              // handleSpeakerChange={this.handleSpeakerChange}
              handleTzChange={this.handleTzChange}
              changeView={this.changeView}
              showModal={this.showModal}
              tz={this.state.tz}
              topics={this.state.topics}
              speakers={this.state.speakers}
              loadData={this.loadData}
            />
            <FullCalendar
              ref={this.calendarRef}
              plugins={[
                // momentTimezonePlugin,
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin
                // listPlugin
              ]}
              headerToolbar={that.state.header}
              windowResize={() => {
                if (window.innerWidth <= 768) {
                  this.changeView('timeGridDay');
                  that.setState({
                    header: {
                      left: '',
                      center: 'title',
                      right: ''
                    }
                  });
                } else {
                  this.changeView('dayGridMonth');
                  that.setState({
                    header: {
                      left: '',
                      center: 'title',
                      right: false
                    }
                  });
                }
              }}
              initialView={this.state.view}
              editable={false}
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: 'short'
              }}
              allDaySlot={false}
              dayMaxEvents={5}
              weekends={this.state.weekendsVisible}
              events={this.state.events}
              eventClick={(calEvent) => {
                that.setState({
                  isModalOpen: true,
                  selectedEvent: calEvent,
                  dayOrDateComparison: 'day',
                  type: 'events'
                });
              }}
              eventContent={(eventInfo) => {
                const type = eventInfo.event._def.extendedProps.type;
                return (
                  <>
                    <span
                      style={{
                        background:
                          type === 'Fully Virtual' ? 'rgb(121, 86, 172)' : 'rgb(239, 60, 77)'
                      }}
                    />
                    <b>{eventInfo.timeText}</b>
                    <i>{eventInfo.event.title}</i>
                  </>
                );
              }}
              customButtons={{
                leftArrow: {
                  text: (
                    <i
                      style={{ color: 'black', display: 'inline-block' }}
                      className="fa fa-chevron-left"
                    />
                  ),
                  click: function () {
                    that.calendarApi.prev();
                  }
                },
                rightArrow: {
                  text: (
                    <i
                      style={{ color: 'black', display: 'inline-block' }}
                      className="fa fa-chevron-right"
                    />
                  ),
                  click: function () {
                    that.calendarApi.next();
                  }
                }
              }}
            />
          </div>

          <Isi
            api_url={api_url}
            events={this.state.events}
            selected={this.state.selected}
            handleEventClick={this.handleEventClick}
            tz={this.state.tz}
          />
          <Modal
            visible={this.state.isModalOpen}
            effect="fadeInUp"
            maxHeight="80%"
            width={this.state.modalWidth.toString()}
            // style={{ background: 'transparent', height: '100vh' }}
            onClickAway={() => this.setState({ isModalOpen: false })}
          >
            <div style={{ overflowY: 'auto', background: '#ac96c7' }}>
              {/* place event render function here */}
              <i
                onClick={() => that.setState({ isModalOpen: false })}
                className="fa fa-times"
                style={{
                  position: 'absolute',
                  fontSize: 22,
                  right: 20,
                  top: 20,
                  cursor: 'pointer',
                  height: 10,
                  width: 'auto',
                  color: 'white'
                }}
              />
              {this.state.isModalOpen && this.renderModalBody()}
            </div>
          </Modal>
        </div>
      </section>
    );
  }
}
