// /*global google*/
import React from 'react';

const Toolbar = (props) => {
  return (
    <>
      <div className="toolbar" style={{ display: 'flex' }}>
        <section className="death-wrapper">
          <div style={{ fontSize: 12 }}>
            Click to view additional information on our{' '}
            <span
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              id="speaker-modal"
              onClick={(e) => {
                e.preventDefault();
                props.showModal('speakers');
              }}
            >
              expert speakers
            </span>{' '}
            and{' '}
            <span
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              id="topic-modal"
              onClick={(e) => {
                e.preventDefault();
                props.showModal('topic');
              }}
            >
              program topics
            </span>
            .
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <label className="label-style" style={{ order: 1 }}>
              <span style={{ minWidth: 60 }}>Filter by:</span>
              <select
                className="tool-select select__filter_by purple"
                style={{ background: '#b093ce !important' }}
                value={props.values.filterTopic}
                onChange={props.handleFilterValueChange('filterTopic')}
              >
                <option value="">All Topics</option>
                {props.topics.map((filter, i) => {
                  return (
                    <option key={i} value={filter}>
                      {filter}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="left label-style" style={{ order: 2 }}>
              <span style={{ minWidth: 60 }}></span>
              <select
                value={props.values.filterSpeaker}
                onChange={props.handleFilterValueChange('filterSpeaker')}
                className="tool-select select__filter_by"
              >
                <option value="">All Speakers</option>
                {props.speakers.map((speaker, i) => {
                  return (
                    <option key={i} value={speaker.id}>
                      {`${speaker.first_name} ${speaker.last_name}`}
                      {speaker.title && `, ${speaker.title}`}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <label className="left label-style" style={{ order: 3 }}>
            <span>View by:</span>
            <select
              className="purple tool-select select__filter_by"
              value={props.values.tzFilter}
              onChange={props.handleFilterValueChange('tzFilter')}
            >
              <option value="America/New_York">Eastern Time</option>
              <option value="America/Chicago">Central Time (CT)</option>
              <option value="America/Denver">Mountain Time (MT)</option>
              <option value="America/Los_Angeles">Pacific Time (PT)</option>
            </select>
          </label>
          <label className="label-style wiggity-target" style={{ order: 4 }}>
            <div className="fc-button-group">
              <button
                className="fc-dayGridMonth-button fc-button fc-button-primary fc-button-active"
                type="button"
                onClick={() => {
                  props.changeView('dayGridMonth');
                }}
              >
                month
              </button>
              <button
                onClick={() => {
                  props.changeView('timeGridWeek');
                }}
                className="fc-timeGridWeek-button fc-button fc-button-primary"
                type="button"
              >
                week
              </button>
              <button
                onClick={() => {
                  props.changeView('timeGridDay');
                }}
                className="fc-timeGridDay-button fc-button fc-button-primary"
                type="button"
              >
                day
              </button>
            </div>
          </label>
        </section>
        <section className="toolbar-form">
          <span style={{ fontSize: 12, textAlign: 'right', fontWeight: 'bold' }}>
            Search for live programs in your area.
          </span>
          <form
            className="radius-form"
            style={{ width: 300 }}
            onSubmit={(e) => {
              e.preventDefault();
              props.loadData(false);
            }}
            onReset={props.resetFilters}
          >
            <label
              style={{
                width: '100%',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                fontSize: 12,
                fontWeight: 'bold',
                marginBottom: 20,
                display: 'flex'
              }}
            >
              <span style={{ marginTop: 20, marginRight: 10 }}>ZIP code:</span>
              <input
                style={{
                  width: '100%',
                  padding: '8px 6px',
                  color: 'black !important',
                  marginLeft: '0 !important',
                  marginTop: '20px',
                  border: '1px solid #e94b31',
                  borderRadius: '0.5em',
                  paddingLeft: '3px !important'
                }}
                value={props.values.zipFilter}
                onChange={props.handleFilterValueChange('zipFilter')}
                type="autocomplete"
                id="zipcode"
              />
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <label
                style={{
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 1
                }}
              >
                <span style={{ marginRight: 22 }}>Radius:</span>
                <select
                  className="select__filter_by"
                  style={{
                    background: 'white !important',
                    border: '1px solid #e94b31 !important',
                    borderRadius: '0.5em',
                    color: 'black',
                    padding: '0px 6px'
                  }}
                  value={props.values.radiusFilter}
                  onChange={props.handleFilterValueChange('radiusFilter')}
                >
                  <option value="5">5 miles</option>
                  <option value="10">10 miles</option>
                  <option value="25">25 miles</option>
                  <option value="50">50 miles</option>
                </select>
              </label>
              <input
                style={{
                  color: 'white',
                  backgroundColor: '#e94b31',
                  padding: '0px 6px',
                  borderRadius: '0.5em',
                  border: 0,
                  marginLeft: '.75em',
                  fontWeight: 'bold',
                  height: '100%',
                  flex: 0.5,
                  cursor: 'pointer'
                }}
                type="submit"
                value="Apply"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <input type="reset" id="resetButton" value="Clear Search" />
            </div>
          </form>
        </section>
      </div>

      <div className="legend">
        <aside>
          <span className="circle purple" />
          <span>Virtual programs</span>
        </aside>
        <aside>
          <span className="circle red" />
          <span>Live programs</span>
        </aside>
      </div>
    </>
  );
};

export default Toolbar;
