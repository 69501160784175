import React from 'react';
import Truncate from 'react-truncate';

const TruncateText = (props) => {
  // const [isOpen, setIsOpen] = useState(false);

  const { isOpen, lines, children } = props;

  const data = isOpen ? (
    <Truncate lines={lines || 1} ellipsis={<span>...</span>}>
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </Truncate>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: children }} />
  );

  return data;
};

export default TruncateText;
