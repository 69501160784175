import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

function StickyWarning(props) {
  const [isHidden, setIsHidden] = useState(false);

  return (
    <div className="sticky-warning" style={{ bottom: isHidden ? -150 : 0 }}>
      <FontAwesomeIcon
        icon={isHidden ? faChevronUp : faChevronDown}
        style={{
          color: 'rgb(239, 60, 77)',
          position: 'absolute',
          top: 10,
          right: 20,
          fontSize: 15
        }}
        onClick={() => {
          setIsHidden(!isHidden);
        }}
      />
      <div className="text" style={{ fontSize: 12 }}>
        <div className="title--purple references__line">IMPORTANT SAFETY INFORMATION</div>
        <div className="border--black references__line">
          <center>WARNING: DIFFERENTIATION SYNDROME</center>
          <table>
            <tbody>
              <tr>
                <td>
                  Patients treated with TIBSOVO have experienced symptoms of diﬀerentiation
                  syndrome, which can be fatal if not treated. Symptoms may include fever, dyspnea,
                  hypoxia, pulmonary inﬁltrates, pleural or pericardial eﬀusions, rapid weight gain
                  or peripheral edema, hypotension, and hepatic, renal, or multi-organ dysfunction.
                  If diﬀerentiation syndrome is suspected, initiate corticosteroid therapy and
                  hemodynamic monitoring until symptom resolution.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StickyWarning;
