import React from 'react';

const speaker_deck_img = require('../../assets/TIBSOVO_Speaker_Deck.jpeg');
const expert_connect_img = require('../../assets/TIBSOVO_Expert_Connect.jpeg');
const case_based_img = require('../../assets/TIBSOVO_Case-Based.jpeg');
const roundtable_img = require('../../assets/TIBSOVO_Roundtable.jpeg');

const imgStyle = { height: 80 };

const TopicContent = () => {
  return (
    <div className="modal__content" style={{ paddingTop: 20 }}>
      <h2>PROGRAM TOPICS</h2>
      <div style={{ marginTop: 10, overflowY: 'auto' }}>
        <div className="program-topic-modal">
          <div className="program-topic-1">
            <img src={speaker_deck_img} alt="pic" style={imgStyle} />
            <div>TIBSOVO Speaker Deck</div>
          </div>
          <div className="program-desc-1">
            AML disease education and TIBSOVO data for both newly diagnosed and R/R indications
          </div>
        </div>
        <div className="program-topic-modal">
          <div className="program-topic-2">
            <img src={expert_connect_img} alt="pic" style={imgStyle} />
            <div>TIBSOVO Expert Connect</div>
          </div>
          <div className="program-desc-2">
            A 30-minute presentation that allows the audience to focus on either the newly diagnosed
            or R/R indication; no disease education component
          </div>
        </div>
        <div className="program-topic-modal">
          <div className="program-topic-1">
            <img src={case_based_img} alt="pic" style={imgStyle} />
            <div>TIBSOVO Case-Based Presentation</div>
          </div>
          <div className="program-desc-1">
            An interactive program highlighting patient journey and treatment considerations to
            better understand which patients may be appropriate for TIBSOVO
          </div>
        </div>
        <div
          className="program-topic-modal"
          // style="border-bottom-left-radius: 1.5em; border-bottom-right-radius: 1.5em"
        >
          <div className="program-topic-2">
            <img src={roundtable_img} alt="pic" style={imgStyle} />
            <div>TIBSOVO Roundtable Program</div>
          </div>
          <div className="program-desc-2">
            A small-group meeting format using a workbook to guide discussions on disease education
            and TIBSOVO data for both newly diagnosed and R/R indications
          </div>
        </div>
        <div style={{ textAlign: 'left', fontSize: 8, marginTop: 10 }}>
          R/R, relapsed or refractory.
        </div>
      </div>
    </div>
  );
};
export default TopicContent;
