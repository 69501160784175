import React, { useState } from 'react';
// import SpeakersList from './SpeakersList';
import StickyWarning from './StickyWarning';
import VizSensor from 'react-visibility-sensor';

const Isi = (props) => {
  const [isVisibleIsi, setIsVisibleIsi] = useState(true);

  return (
    <div className="isi">
      {!isVisibleIsi && <StickyWarning />}
      {/*No references in ISI*/}
      {/* <div className="references__line">
        Faculty are paid speakers presenting on behalf of Jazz Pharmaceuticals, Inc.{' '}
      </div>
      <div className="references__line">
        <span className="title--black">References:</span> <b>1.</b>XYWAV [package insert]. Palo
        Alto, CA: Jazz Pharmaceuticals, Inc; 2020. <b>2.</b>Thorpy MJ. <i>CNS Drugs.</i>{' '}
        2020;34(1):9-27. <b>3.</b>Data on File (JZP258-2020-008). Jazz Pharmaceuticals, Inc.
      </div> */}
      {/*DOESNT SEEM LIKE THEY WANT SPEAKER LIST*/}
      {/* <SpeakersList
        tz={props.tz}
        api_url={props.api_url}
        events={props.events}
        selected={props.selected}
        handleEventClick={props.handleEventClick}
      /> */}
      <VizSensor
        partialVisibility
        onChange={(isVisible) => {
          setIsVisibleIsi(isVisible);
        }}
      >
        <div>
          <div className="title--purple references__line">IMPORTANT SAFETY INFORMATION</div>
          <div className="border--black references__line">
            <center>WARNING: DIFFERENTIATION SYNDROME</center>
            Patients treated with TIBSOVO have experienced symptoms of differentiation syndrome,
            which can be fatal if not treated. Symptoms may include fever, dyspnea, hypoxia,
            pulmonary infiltrates, pleural or pericardial effusions, rapid weight gain or peripheral
            edema, hypotension, and hepatic, renal, or multi-organ dysfunction. If differentiation
            syndrome is suspected, initiate corticosteroid therapy and hemodynamic monitoring until
            symptom resolution.
          </div>
          <div className="references__line" style={{ marginTop: 16 }}>
            <div className="title--black--underline small_margin_bottom">
              Warnings and Precautions
            </div>
            <div className="small_margin_bottom">
              <span style={{ fontWeight: '600' }}>
                Differentiation Syndrome: See Boxed WARNING.
              </span>{' '}
              In the clinical trial, 25% (7/28) of patients with newly diagnosed AML and 19%
              (34/179) of patients with relapsed or refractory AML treated with TIBSOVO experienced
              differentiation syndrome. Differentiation syndrome is associated with rapid
              proliferation and differentiation of myeloid cells and may be life-threatening or
              fatal if not treated. Symptoms of differentiation syndrome in patients treated with
              TIBSOVO included noninfectious leukocytosis, peripheral edema, pyrexia, dyspnea,
              pleural effusion, hypotension, hypoxia, pulmonary edema, pneumonitis, pericardial
              effusion, rash, fluid overload, tumor lysis syndrome, and creatinine increased. Of the
              7 patients with newly diagnosed AML who experienced differentiation syndrome, 6 (86%)
              patients recovered. Of the 34 patients with relapsed or refractory AML who experienced
              differentiation syndrome, 27 (79%) patients recovered after treatment or after dose
              interruption of TIBSOVO. Differentiation syndrome occurred as early as 1 day and up to
              3 months after TIBSOVO initiation and has been observed with or without concomitant
              leukocytosis.
            </div>
            <div className="small_margin_bottom">
              If differentiation syndrome is suspected, initiate dexamethasone 10 mg IV every 12
              hours (or an equivalent dose of an alternative oral or IV corticosteroid) and
              hemodynamic monitoring until improvement. If concomitant noninfectious leukocytosis is
              observed, initiate treatment with hydroxyurea or leukapheresis, as clinically
              indicated. Taper corticosteroids and hydroxyurea after resolution of symptoms and
              administer corticosteroids for a minimum of 3 days. Symptoms of differentiation
              syndrome may recur with premature discontinuation of corticosteroid and/or hydroxyurea
              treatment. If severe signs and/or symptoms persist for more than 48 hours after
              initiation of corticosteroids, interrupt TIBSOVO until signs and symptoms are no
              longer severe.
            </div>
            <div className="small_margin_bottom">
              <span style={{ fontWeight: '600' }}>QTc Interval Prolongation:</span> Patients treated
              with TIBSOVO can develop QT (QTc) prolongation and ventricular arrhythmias. One
              patient developed ventricular fibrillation attributed to TIBSOVO. Concomitant use of
              TIBSOVO with drugs known to prolong the QTc interval (e.g., anti-arrhythmic medicines,
              fluoroquinolones, triazole anti-fungals, 5-HT3 receptor antagonists) and CYP3A4
              inhibitors may increase the risk of QTc interval prolongation. Conduct monitoring of
              electrocardiograms (ECGs) and electrolytes. In patients with congenital long QTc
              syndrome, congestive heart failure, or electrolyte abnormalities, or in those who are
              taking medications known to prolong the QTc interval, more frequent monitoring may be
              necessary.
            </div>
            <div className="small_margin_bottom">
              Interrupt TIBSOVO if QTc increases to greater than 480 msec and less than 500 msec.
              Interrupt and reduce TIBSOVO if QTc increases to greater than 500 msec. Permanently
              discontinue TIBSOVO in patients who develop QTc interval prolongation with signs or
              symptoms of life-threatening arrhythmia.
            </div>
            <div className="small_margin_bottom">
              <span style={{ fontWeight: '600' }}>Guillain-Barré Syndrome:</span> Guillain-Barré
              syndrome occurred in &lt;1% (2/258) of patients treated with TIBSOVO in the clinical
              study. Monitor patients taking TIBSOVO for onset of new signs or symptoms of motor
              and/or sensory neuropathy such as unilateral or bilateral weakness, sensory
              alterations, paresthesias, or difficulty breathing. Permanently discontinue TIBSOVO in
              patients who are diagnosed with Guillain-Barré syndrome.
            </div>
            <div className="title--black--underline small_margin_bottom" style={{ marginTop: 16 }}>
              Adverse Reactions
            </div>
            <div className="small_margin_bottom">
              <b>&bull;</b> The most common adverse reactions including laboratory abnormalities
              (≥20%) were hemoglobin decreased (60%), fatigue (43%), arthralgia (39%), calcium
              decreased (39%), sodium decreased (39%), leukocytosis (38%), diarrhea (37%), magnesium
              decreased (36%), edema (34%), nausea (33%), dyspnea (32%), uric acid increased (32%),
              potassium decreased (32%), alkaline phosphatase increased (30%), mucositis (28%),
              aspartate aminotransferase increased (27%), phosphatase decreased (25%),
              electrocardiogram QT prolonged (24%), rash (24%), creatinine increased (24%), cough
              (23%), decreased appetite (22%), myalgia (21%), constipation (20%), and pyrexia (20%).
            </div>
            <div className="small_margin_bottom">
              <b>&bull;</b>{' '}
              <span style={{ fontWeight: '600' }}>In patients with newly diagnosed AML,</span> the
              most frequently reported Grade ≥3 adverse reactions (≥5%) were fatigue (14%),
              differentiation syndrome (11%), electrocardiogram QT prolonged (11%), diarrhea (7%),
              nausea (7%), and leukocytosis (7%). Serious adverse reactions (≥5%) were
              differentiation syndrome (18%), electrocardiogram QT prolonged (7%), and fatigue (7%).
              There was one case of posterior reversible encephalopathy syndrome (PRES).
            </div>
            <div className="small_margin_bottom">
              <b>&bull;</b>{' '}
              <span style={{ fontWeight: '600' }}>
                In patients with relapsed or refractory AML,
              </span>{' '}
              the most frequently reported Grade ≥3 adverse reactions (≥5%) were differentiation
              syndrome (13%), electrocardiogram QT prolonged (10%), dyspnea (9%), leukocytosis (8%),
              and tumor lysis syndrome (6%). Serious adverse reactions (≥5%) were differentiation
              syndrome (10%), leukocytosis (10%), and electrocardiogram QT prolonged (7%). There was
              one case of progressive multifocal leukoencephalopathy (PML).
            </div>
            <div className="title--black--underline small_margin_bottom" style={{ marginTop: 16 }}>
              Drug Interactions
            </div>
            <div className="small_margin_bottom">
              <span style={{ fontWeight: '600' }}>Strong or Moderate CYP3A4 Inhibitors:</span>{' '}
              Reduce TIBSOVO dose with strong CYP3A4 inhibitors. Monitor patients for increased risk
              of QTc interval prolongation.
            </div>
            <div className="small_margin_bottom">
              <span style={{ fontWeight: '600' }}>Strong CYP3A4 Inducers:</span> Avoid concomitant
              use with TIBSOVO.
            </div>
            <div className="small_margin_bottom">
              <span style={{ fontWeight: '600' }}>Sensitive CYP3A4 Substrates</span> Avoid
              concomitant use with TIBSOVO.
            </div>
            <div className="small_margin_bottom">
              <span style={{ fontWeight: '600' }}>QTc Prolonging Drugs:</span> Avoid concomitant use
              with TIBSOVO. If co-administration is unavoidable, monitor patients for increased risk
              of QTc interval prolongation.
            </div>
            <div className="title--black--underline small_margin_bottom" style={{ marginTop: 16 }}>
              LACTATION
            </div>
            <div className="small_margin_bottom">
              Because many drugs are excreted in human milk and because of the potential for adverse
              reactions in breastfed children, advise women not to breastfeed during treatment with
              TIBSOVO and for at least 1 month after the last dose.
            </div>
            <div
              className="small_margin_bottom"
              style={{ marginTop: 16, fontSize: 12, fontWeight: 600 }}
            >
              Attendee and Reporting Policy
            </div>
            <div className="small_margin_bottom">
              TIBSOVO educational programs are intended for US healthcare professionals only. Guests
              and spouses may not attend the program. The value of any meals or items provided to
              program participants may be reported in accordance with applicable federal and state
              laws. Healthcare professionals from certain states or institutions that restrict
              healthcare professionals from accepting meals or other transfers of value from
              pharmaceutical companies must adhere to all restrictions and requirements imposed by
              the law or their institution.
            </div>
          </div>
          <b>
            Please see full{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black' }}
              href="https://www.tibsovopro.com/pdf/prescribinginformation.pdf"
            >
              Prescribing Information
            </a>
            , including BOXED Warning.
          </b>
          <div className="footer-section">
            <div className="footer-logo-text">
              <img src={require('../assets/logo.png')} alt="Servier Logo" style={{ width: 150 }} />
            </div>
            {/* <div className="footer-links">
              <div>
                <a
                  href="https://www.jazzpharma.com/privacy-statement/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Statement
                </a>
              </div>
              <div style={{ width: 30 }}>&nbsp;</div>
              <div>
                <a
                  href="https://www.jazzpharma.com/terms-of-use/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </div>
            </div> */}
            <div style={{ textAlign: 'center', fontSize: 10 }}>
              <div>
                &copy;2021 Servier Pharmaceuticals LLC. Boston, MA 02210. All rights reserved.
              </div>
              <div style={{ fontSize: 6 }}>&nbsp;</div>
              <div>
                Customer Service: 1-800-807-6124. Servier and the Servier Logo are registered
                trademarks of LES LABORATOIRES SERVIER.
              </div>
              <div>
                TIBSOVO is a registered trademark of SERVIER IP UK LTD, a wholly owned, indirect
                subsidiary of LES LABORATOIRES SERVIER.
              </div>
              <div style={{ fontSize: 6 }}>&nbsp;</div>
              <div>IVO-US-0681 04/21</div>
            </div>
            <div style={{ height: 15 }}>&nbsp;</div>
          </div>{' '}
        </div>
      </VizSensor>
    </div>
  );
};
export default Isi;
