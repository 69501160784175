import React from 'react';
import SpeakerCard from './SpeakerCard';

const SpeakersList = (props) => {
  // const [speakers, setSpeakers] = useState(false);

  // const getSpeakers = async () => {
  //   const presenterJson = await fetch(`${api_url}/presenters`);
  //   setSpeakers([...(await presenterJson.json())]);
  // };

  const { speakers } = props;

  // useEffect(() => {
  //   getSpeakers();
  // }, [speakers]);

  // if (!speakers) {
  //   return <div>Loading Speakers....</div>;
  // }

  return (
    <div className="speakers-list">
      <div
        className="title"
        style={{
          fontWeight: 'bold',
          display: 'bloack',
          color: 'white',
          fontSize: 22,
          textAlign: 'center',
          marginBottom: 20
        }}
      >
        Expert Speakers
      </div>
      <div
        style={{
          background: 'white',
          borderRadius: '30px',
          maxHeight: 300,
          overflow: 'auto',
          padding: 20
        }}
      >
        {speakers.map((s, idx) => (
          <SpeakerCard
            tz={props.tz}
            key={idx}
            speaker={s}
            selected={props.selected}
            handleEventClick={props.handleEventClick}
          />
        ))}
      </div>
    </div>
  );
};

export default SpeakersList;
