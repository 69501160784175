import React, { useState } from 'react';
// import moment from 'moment';
import TruncateText from '../TruncateText';

const SpeakerCard = (props) => {
  const [toggleClicked, setToggleClicked] = useState(true);

  const toggleSpeaker = () => {
    setToggleClicked(!toggleClicked);
  };

  const { speaker } = props;

  return (
    <div className="speaker">
      <div className="speaker-toggle" onClick={toggleSpeaker}>
        {toggleClicked ? '+' : '-'}
      </div>
      <div className="speaker-info">
        <div className="speaker-title">
          {speaker.first_name} {speaker.last_name}
          {speaker.title && `, ${speaker.title}`}
          {speaker.specialties && `, ${speaker.specialties}`}
          <br />
          {speaker.affiliation} - {speaker.city}, {speaker.state}
        </div>
        <div onClick={toggleSpeaker}>
          <TruncateText isOpen={toggleClicked} lines={2}>
            {speaker.bio}
          </TruncateText>
        </div>
        {/* <div className="scheduled-text">Scheduled Program(s):</div> */}
        {/* <div style={{ display: 'flex', alignItems: 'space-between' }}>
          {speaker.events.map((p, idx) => {
            const m = moment.tz(p['start_date'], 'America/New_York');
            const mtz = m.tz(props.tz).format('MMMM Do, h:mm a z');

            if (m > moment()) {
              return (
                <button
                  onClick={() => {
                    props.handleEventClick(p);
                  }}
                  key={idx}
                  style={{
                    marginRight: 10,
                    marginTop: 10,
                    borderRadius: 4,
                    borderWidth: 1,
                    background: 'transparent',
                    padding: 10,
                    cursor: 'pointer'
                  }}
                >
                  {mtz}
                </button>
              );
            }
          })}
        </div> */}
      </div>
    </div>
  );
};

export default SpeakerCard;
